// @ts-nocheck
import { Button } from '@shopify/polaris';

const GetSupportButton = () => (
  <Button
    onClick={() => {
      //   window.$crisp.push(['set', 'session:segments', [['invoicehero']]]);
      //   window.$crisp.push([
      //     'set',
      //     'session:data',
      //     ['storename', window.shopName],
      //   ]);
      $crisp.push(['do', 'chat:open']);
    }}
  >
    Get Support
  </Button>
);

export default GetSupportButton;

import type { PageProps } from '@shopify/polaris';

export default function useHelpAndSupportAction(): PageProps['primaryAction'] {
  return {
    content: 'Book a Demo',
    onAction() {
      window.open('https://calendly.com/mlveda-team', '_blank');
    },
  };
}

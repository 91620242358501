import { StrictMode, useEffect } from 'react';
import enTranslations from '@shopify/polaris/locales/en.json';
import { createRoot } from 'react-dom/client';
import {
  BrowserRouter as Router,
  Link,
  useNavigate,
  useLocation,
} from 'react-router-dom';
import { AppProvider } from '@shopify/polaris';
import type { LinkLikeComponentProps } from '@shopify/polaris/build/ts/src/utilities/link/types';
import ReactGA4 from 'react-ga4';
import AppQueryClientProvider from './contexts/react-query.context';
import App from './App';
import ShopifySupportLink from './ShopifySupportLink';

if (process.env.REACT_APP_GA_ID) {
  console.log('found react ga id');
  ReactGA4.initialize(process.env.REACT_APP_GA_ID, {
    gaOptions: {
      cookieFlags: 'max-age=7200;secure;samesite=none',
    },
    gtagOptions: {
      debug_mode: true,
    },
  });

  // Example event tracking (do this AFTER initialization):
  ReactGA4.event({
    category: 'User',
    action: 'ButtonClicked',
    label: 'Add to Cart',
  });
}

function LinkLikeComponent(props: LinkLikeComponentProps) {
  const { url } = props;
  return <Link {...props} to={url} />;
}

function ReportNavigations() {
  const { pathname, search } = useLocation();

  useEffect(() => {
    document.addEventListener('click', () => {
      ReactGA4.event('click', {
        page_title: document.title,
        page_location: window.location.pathname + window.location.search,
      });
    });
  }, []);

  useEffect(() => {
    ReactGA4.event('page_view', {
      page_title: document.title,
      page_location: pathname + search,
    });
  }, [pathname, search]);

  return null;
}

function Main({ isSetupCompleted }: { isSetupCompleted: boolean }) {
  // const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  // const location = useLocation();

  useEffect(() => {
    if (!isSetupCompleted) {
      navigate('/step1');
    }
    // setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const router = useMemo(
  //   () => ({
  //     location,
  //     history,
  //   }),
  //   [location, history],
  // );

  return (
    <AppQueryClientProvider>
      <AppProvider linkComponent={LinkLikeComponent} i18n={enTranslations}>
        <App />
      </AppProvider>
    </AppQueryClientProvider>
  );
}

export default function renderMainApp(isSetupCompleted = true) {
  const root = createRoot(document.getElementById('root') as HTMLElement);

  root.render(
    <StrictMode>
      <Router>
        <ReportNavigations />
        <ShopifySupportLink />
        <Main isSetupCompleted={isSetupCompleted} />
      </Router>
    </StrictMode>,
  );
}

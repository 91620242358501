import { PropsWithChildren } from 'react';
import { Banner, LegacyCard, Page } from '@shopify/polaris';
import { useLocalStorage } from 'usehooks-ts';
import useHelpAndSupportAction from '../../hooks/useHelpAndSupportAction';
import GetSupportButton from '../../components/GetSupportButton';

export default function DashboardPageLayout({ children }: PropsWithChildren) {
  const primaryAction = useHelpAndSupportAction();
  const [reportBannerVisible, setReportBannerVisible] = useLocalStorage(
    'report-banner-visible',
    true,
  );
  return (
    <Page
      primaryAction={primaryAction}
      secondaryActions={<GetSupportButton />}
      title='Report'
      fullWidth
    >
      {reportBannerVisible ? (
        <div style={{ marginBottom: '1rem' }}>
          <Banner tone='info' onDismiss={() => setReportBannerVisible(false)}>
            Now you can view/download your invoices from your orders page
            itself. Select particular orders (50 max) or open a particular order
            and choose Invoice Hero features from More actions drop down.
          </Banner>
        </div>
      ) : undefined}
      <LegacyCard>{children}</LegacyCard>
    </Page>
  );
}

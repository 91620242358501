import {
  SkeletonDisplayText,
  Text,
  Layout,
  LegacyCard,
  Page,
  Banner,
} from '@shopify/polaris';
import { type ReactNode, useEffect } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { useLocalStorage } from 'usehooks-ts';
import { useAnalyticsQuery } from '../../services/queries.service';
import classes from './Index.module.scss';
import useHelpAndSupportAction from '../../hooks/useHelpAndSupportAction';
import GetSupportButton from '../../components/GetSupportButton';

type Props = {
  isLoading: boolean;
  title: string;
  number?: ReactNode;
};

const AnalyticsCard = ({ isLoading, title, number }: Props) => (
  <div className={classes.cardContainer}>
    <LegacyCard sectioned title={title}>
      {isLoading ? (
        <SkeletonDisplayText size='medium' />
      ) : (
        <Text variant='heading2xl' as='p'>
          {number}
        </Text>
      )}
    </LegacyCard>
  </div>
);

export default function AnalyticsPage() {
  const { data, isLoading } = useAnalyticsQuery();
  const queryClient = useQueryClient();
  const primaryAction = useHelpAndSupportAction();
  const [dashboardBannerVisible, setDashboardBannerVisible] = useLocalStorage(
    'dashboard-banner-visible',
    true,
  );

  useEffect(() => {
    import('../dashboard/Index');
  }, []);

  useEffect(() => {
    queryClient.refetchQueries(['analytics']).catch(console.error);
  }, [queryClient]);

  return (
    <Page
      title='Analytics'
      primaryAction={primaryAction}
      secondaryActions={<GetSupportButton />}
    >
      <div className={classes.layoutContainer}>
        <Layout>
          <Layout.Section variant='fullWidth'>
            {dashboardBannerVisible ? (
              <Banner
                tone='info'
                onDismiss={() => setDashboardBannerVisible(false)}
              >
                Now you can view/download your invoices from your orders page
                itself. Select particular orders (50 max) or open a particular
                order and choose Invoice Hero features from More actions drop
                down.
              </Banner>
            ) : undefined}
          </Layout.Section>
          <Layout.Section variant='oneThird'>
            <AnalyticsCard
              number={data?.totalInvoicesGeneratedLastMonth}
              isLoading={isLoading}
              title='Total Invoices Generated Last Month'
            />
          </Layout.Section>
          <Layout.Section variant='oneThird'>
            <AnalyticsCard
              isLoading={isLoading}
              title='Total Invoice Emails Sent For Orders In Last Month'
              number={data?.totalInvoicesSentLastMonth}
            />
          </Layout.Section>
          <Layout.Section variant='oneThird'>
            <AnalyticsCard
              title='Total Invoices Generated This Month'
              isLoading={isLoading}
              number={data?.totalInvoicesGeneratedThisMonth}
            />
          </Layout.Section>
          <Layout.Section variant='oneThird'>
            <AnalyticsCard
              title='Total Invoices Sent This Month'
              isLoading={isLoading}
              number={data?.totalInvoicesSentThisMonth}
            />
          </Layout.Section>
        </Layout>
      </div>
    </Page>
  );
}

import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';

export default function ShopifySupportLink() {
  const navigate = useNavigate();

  useEffect(() => {
    shopify.support.registerHandler?.(() => {
      navigate('/support');
      // @ts-ignore
      window.$crisp = window.$crisp || [];
      // @ts-ignore
      window.$crisp.push(['do', 'chat:open']);
    });
  }, [navigate]);

  return null;
}

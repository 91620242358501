import { BrowserRouter, Routes, Route } from 'react-router-dom';
import ViewInvoiceForOrder from './pages/ViewInvoiceForOrder';
import SendInvoiceToCustomerForOrder from './pages/SendInvoiceToCustomerForOrder';
import SendInvoiceToCustomerForDraftOrder from './pages/SendInvoiceToCustomerForDraftOrder';
import ViewInvoiceForDraftOrder from './pages/ViewInvoiceForDraftOrder';
import BulkAction from './pages/BulkAction';
import Home from './pages/Home';
import SentryMonitoring from '../components/SentryMonitoring';
import ShopifySupportLink from '../ShopifySupportLink';

const RedirectToPricingPage = () => {
  window.location.pathname = '/pricing';
  return null;
};

export default function AdminLinksApp() {
  return (
    <>
      <BrowserRouter basename='/admin-links'>
        <ShopifySupportLink />
        <Routes>
          <Route
            path='/api/order/bulk'
            element={
              <BulkAction
                goBackUrlInAdmin='/orders'
                url='/api/order/bulk'
                successMessage='You will receive an email having a link to download the invoices of the orders you have selected shortly.'
              />
            }
          />
          <Route
            path='/api/order/bulk/print'
            element={
              <BulkAction
                goBackUrlInAdmin='/orders'
                url='/api/order/bulk/print'
                successMessage='We will be sending merged invoice pdf of the orders you have selected to your email id.'
              />
            }
          />
          <Route
            path='/api/order/bulk/send'
            element={
              <BulkAction
                goBackUrlInAdmin='/orders'
                url='/api/order/bulk/send'
                successMessage="We will be sending invoices of the orders you have selected to the respective customer's email ids. You will receive a confirmation email to once the invoices are sent."
              />
            }
          />
          <Route
            path='/shopify/generateInvoice/getLink'
            element={<ViewInvoiceForOrder />}
          />
          <Route
            path='/api/order/invoice'
            element={<SendInvoiceToCustomerForOrder />}
          />
          <Route
            path='/api/order/draft/send'
            element={<SendInvoiceToCustomerForDraftOrder />}
          />
          <Route
            path='/api/order/draft/view'
            element={<ViewInvoiceForDraftOrder />}
          />
          <Route path='/pricing' element={<RedirectToPricingPage />} />
          <Route index element={<Home />} />
        </Routes>
      </BrowserRouter>
      <SentryMonitoring />
    </>
  );
}
